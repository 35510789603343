import { Component, ChangeDetectionStrategy, OnInit, signal, WritableSignal } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import { Contact, Feedback, FEEDBACK_STATUSES } from '@app/shared'
import { filter } from 'rxjs'

@Component({
    selector: 'app-feedback-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './feedback-page.component.html',
    styleUrls: [`./feedback-page.component.css`],
    standalone: false
})
export class FeedbackPageComponent implements OnInit{

  windowType: WritableSignal<'rating' | 'thanks' | 'repost' | 'description' | ''> = signal('');

  feedback: Feedback;

  ratingValue: number;
  descriptionValue: string = '';
  selectedSite: string;
  redirectUrl: string;

  orderId: string;
  account: Contact;
  urlList: { [key: string]: any };
  linksCount: number = 0;

  medium: 'email' | 'phone'

  constructor(
    private store: Store<fromCoreStore.State>,
    private route: ActivatedRoute,
  ){}

  ngOnInit() {
    // Getting orderId from route (v/orderId:/feedback)
    this.orderId = this.route?.snapshot?.params?.orderId
    // From where did user come, from mobile link or email link
    this.medium = this.route?.snapshot?.queryParams?.email ? 'email' : 'phone'

    // Getting all info
    this.store.dispatch(new fromCoreStore.GetPublicFeedback({orderId: this.orderId}))

    this.store.select(fromCoreStore.selectAllFeedbacks).pipe(
      filter((feedbacks) => feedbacks && feedbacks?.length > 0)
    ).subscribe(feedbacks => {
      this.feedback = feedbacks[0]

      if(this.feedback){
        // List of links that are setted at my company settings
        this.urlList = this.feedback?._local?.urlList
        this.linksCount = this.urlList ? Object.keys(this.urlList)?.length : 0

        switch(this.feedback.status) {
          case FEEDBACK_STATUSES.REVIEWED: {
            this.windowType.set('thanks')
            break
          }
          case FEEDBACK_STATUSES.SENT: {
            this.windowType.set('rating')
            this.store.dispatch(new fromCoreStore.PublicUpdateFeedback({orderId: this.orderId, status: FEEDBACK_STATUSES.OPENED}))
            break
          }
          case FEEDBACK_STATUSES.OPENED: {
            this.windowType.set('rating')
            break
          }
        }
      }

    })
  }

  selectRating(value:number) {
    this.ratingValue = value;
    // Define where we go next
    if(this.ratingValue > 3){
      if(this.linksCount === 0 || this.feedback.internal){
        this.windowType.set('description')
      }else if(this.linksCount > 0){
        this.windowType.set('repost')
      }
    }else{
      this.windowType.set('description')
    }
  }

  changeDesc(event:any){
    this.descriptionValue = event?.target?.value
  }

  selectSite(value:string) {
    this.selectedSite = value
    this.redirectUrl = this.urlList[value]?.url
    this.sendFeedback()
  }

  sendFeedback(){
    let feedbackConfig = {
      orderId: this.orderId,
      rating: this.ratingValue,
      medium: this.medium,
      status: FEEDBACK_STATUSES.REVIEWED
    }

    // If he send feedback from description view that means he is living it from haulex platform
    if (this.windowType() === 'description') {
      this.store.dispatch(new fromCoreStore.PublicUpdateFeedback({
        ...feedbackConfig,
        description: this.descriptionValue,
        platform: 'haulex'
      }))
      this.windowType.set('thanks')
    }
    // If he send feedback from repost view that means he pressed some review button and we have to redirect him
    else if (this.windowType() === 'repost') {
      window.open(this.redirectUrl, '_blank');
      this.store.dispatch(new fromCoreStore.PublicUpdateFeedback({
        ...feedbackConfig,
        platform: this.selectedSite,
      }))
      this.windowType.set('thanks')
    }
  }

}
